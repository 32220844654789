import React from 'react'
import HotelPreview from './components/HotelReview/HotelPreview'
import { useParams } from 'react-router-dom';
import MehndiArtistPreview from './MehndiArtistPreview';
const MehndiArtistSingle = () => {
    let { id } = useParams();
    return (
        <MehndiArtistPreview id={id} />
    )
}

export default MehndiArtistSingle