import React from 'react'
import { useState } from 'react';
import './hotel.css';
const HotelForm = () => {
  const [data, setData] = useState({
    name: '',
    mobno: '',
    date: '',
    event: '',
  });
  const formHandler = (e) => {

    const name = e.target.name;

    const value = e.target.value;

    setData({ ...data, [name]: value })
  }
  const btnHandler = (e) => {
    e.preventDefault();
    alert(`You submitted Name : ${data.name} and mobno is ${data.mobno} and Event Data is ${data.date} and event type is ${data.event}`)
  }
  return (
    <>
      {/* submit with merchant_id enquiry module*/}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card form_preview'>
              <div className='card-body'>
                <form>
                  <div className='form-group'>
                    <input type="text" placeholder='Full Name*' value={data.name} onChange={formHandler} name='name' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <input type="tel" placeholder='Mobile Number*' onChange={formHandler} name='mobno' value={data.mobno} className='form-control' />
                  </div>
                  <div className='row'>
                    <div className='mb-1 col-12'>
                      <input type="text" placeholder='Function Date*' onChange={formHandler} name='date' value={data.date} className='form-control'
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </div>
                    <div className='form-group mb-3' >
                      <select class="form-select fun_type" onChange={formHandler} value={data.event} name='event' style={{ backgroundColor: "#fff" }}>
                        <option selected>Function Type </option>
                        <option value="Wedding">Wedding</option>
                        <option value="Birthday Party">Birthday Party</option>
                        <option value="Engagement">Engagement</option>
                        <option value="Anniversary">Anniversary</option>
                      </select>

                    </div>
                  </div>

                  <button className='btn sbt_btn form-control' onClick={btnHandler}>Send</button>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HotelForm