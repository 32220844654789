import React from 'react'
import { useParams } from 'react-router-dom';
import VendorContainer from './VendorContainer'
const Vendors = () => {
  let { cat_id, id } = useParams();
  return (
    <VendorContainer cat_id={cat_id} id={id} />
    // <h1>Cat id is {id}</h1>
  )
}

export default Vendors