import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { BsFillStarFill } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";

const MehndiBox = ({ main_image, small_1, small_2, small_3, title, avg_rating, review, location, price, id }) => {
    const url = useLocation();
    const path = url.pathname;
    return (
        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <Link to={`${path}/v1/${id}`} style={{ textDecoration: 'none', color: 'black' }}>
                <div className="card card_container">
                    <div className="card-header p-0">
                        <img src={main_image} alt="" class="img-style" style={{ height: '220px' }} />
                    </div>
                    <div className="three_section">
                        <img src={small_1} alt="" class="three-style" />
                        <img src={small_2} alt="" class="three-style" />
                        <img src={small_3} alt="" class="three-style" />
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h5 class="title-1">{title}</h5>
                            </div>
                            <div className="rating">
                                <BsFillStarFill className="text-warning" />
                                <span>{avg_rating}</span>
                                ({review} reviews)
                            </div>
                        </div>

                        <div class="d-flex">
                            <BiMap className="text-dark font-weight-bold" /><p className="text-color-1">{location}</p>
                        </div>
                        <div>
                            <b>Starting onwords</b>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">

                            <div>&#x20B9; {price}</div>
                            <div> <BsHeart className="heart-color" /></div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default MehndiBox