import "./Blog.css";
import React, { useState, useEffect } from "react";
import { BsHeartFill } from "react-icons/bs";
import { Link } from 'react-router-dom'
import axios from "axios";
const Blog = ({ id, title, img, desc }) => {
  const link = 'https://eventplanet.in/api/auth/blog';
  const [blog, setBlog] = useState([]);
  const blogFinder = async () => {
    const res = await axios.get(link);
    setBlog(res.data.data)
  }
  useEffect(() => {
    blogFinder()
  }, [])

  return (
    <>
      <div className="col-md-4">
        <Link to={`/blog/${id}`} className="blog_anchor">
          <div className="card blog_container shadow-sm m-2">
            <div className="card-header p-0">
              <img
                src={img}
                alt="Event Planet"
                className="img-fluid"
                style={{ height: "250px" }}
              />
            </div>
            <div className="card-body">
              <h5 className="card-title"> {`${title.length > 25 ? title.slice(0, 25) : title}...`}</h5>

              <div className="text-section">
                <p className="card-text text-muted">
                  <div
                    dangerouslySetInnerHTML={{ __html: `${desc.length > 80 ? desc.slice(0, 80) : desc}...` }}
                  />

                </p>
              </div>

              <div className="d-flex justify-content-between">
                <div className="time">03 Mar 2022</div>
                <div className="icon">
                  <BsHeartFill className="text-danger" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Blog;