import React, { useState, useEffect } from 'react'
import './SubCategory.css';
import db from '../firebase';
import { useParams, useRouteMatch } from 'react-router-dom';
import { query, where, collection, getDocs } from 'firebase/firestore';

const ServiceSubCategory = ({ cat_id, handleClick }) => {
  console.log('Sub Category Page ', cat_id)
  let param = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const mycollection = collection(db, 'sub_categories');
      let q = query(mycollection, where('cat_id', '==', cat_id));
      const data = await getDocs(q);
      setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    getCategories();
  }, [cat_id])
  return (
    <div className="container-fluid">
      <div className={`sub_category`}>
        <div className="row">
          {
            data.map((val, i) => {
              return (
                <div className="col-md-3 d-flex justify-content-center p-2 item" key={i} onClick={event => handleClick(event, val.id)}>
                  {val.sub_cat_name}
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  )
}

export default ServiceSubCategory