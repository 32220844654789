import React from 'react'
import './weddingpackage.css'
import weddingPackage from './weddingpackage.png'
const WeddingPackage = () => {
    return (
        <div className='container'>
            <div className='row pt-2'>
                <div className='col-md-12'>
                    <h4 style={{ fontWeight: '700', marginLeft: '-15px' }}>Bundle Packages</h4>
                </div>
            </div>
            <div className='row pb-3'>
                <div className='wedding' style={{ backgroundImage: `url(${weddingPackage})` }}>
                    <p className='package_heading'>ALL-INCLUSIVE WEDDING PACKAGES</p>
                    <button className='explore'>Explore Now</button>
                </div>
            </div>
        </div>
    )
}

export default WeddingPackage