import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './checklist.css'
import checklist from './checklist.jpg'
import underline from './underline.png'
import weddings1 from './weddings1.png'
import weddings2 from './weddings2.png'
import weddings3 from './weddings3.png'
import weddings4 from './weddings4.png'

const Card = (props) => {
    return (
        <div className='col-md-3'>
            <Link to={props.href} class="link_style">
                <img src={props.img} alt='wedding4' style={{ height: '126px' }} />
                <p style={{ fontSize: '20px', fontWeight: '700' }}>{props.name}</p>
            </Link>
        </div>
    )
}

const Checklist = (props) => {
    const [data, setData] = useState([
        {
            name: 'Wedding Quatation',
            image: weddings1,
            href: 'wedding-checklist',
            title: "Let's start new journey at best price"
        },
        {
            name: 'Anniversary Quatation',
            image: weddings2,
            href: 'anniversary-checklist',
            title: "Let's celebrate one more year of hapiness"
        },
        {
            name: 'Corporate Event Quatation',
            image: weddings3,
            href: 'corporate-event-checklist',
            title: "Let's create more value to your business"
        },
        {
            name: 'Birthday Quatation',
            image: weddings4,
            href: 'birthday-checklist',
            title: "Let's us organise best memorable birthday for you"
        },
    ])
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='checklist'>
                        <div className=' text-center mt-3'>
                            <p className='chechlist_heading'>Get Your Quotation</p>
                            <img src={underline} alt='underline' className='img-fluid' alt="Underline Image" />
                        </div>
                        <div className='row text-center my-3' style={{ fontSize: '23px', fontWeight: '500' }}>
                            {
                                data.map((val) => {
                                    return (
                                        <>
                                            <Card name={val.name} img={val.image} title={val.title} href={val.href} />
                                        </>
                                    )

                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checklist