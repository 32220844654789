import { useState } from 'react'
import './Searchbar.css'
import { Link } from 'react-router-dom'
const SearchBar = () => {
  const [data, setData] = useState();
  const btnHandler = () => {
    alert("Process search for " + data);
  }
  return (
    <div>
      <div className='container-fluid bg-white'>
        <div className='row'>
          <nav className="navbar navbar-expand-lg bg-light bg-white">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex text-center ">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/">Explorting as a guest</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " aria-current="page" href="/">Login/Signup</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " aria-current="page" to="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className='row my-5'>
          <div className='col-md-4 mx-auto'>
            <center>
              <Link to="/"><img src='https://eventplanet.in/assets/home/logo_main.png' style={{ height: '113.37px', marginBottom: '40px' }} alt="Event Planet" /></Link>
            </center>
            <div className='d-flex justify-content-center align-items-center'>
              <div class="" style={{ fontSize: '48px', fontWeight: 'bold' }}>Se</div>
              <div className='search'>
                <i className="fa-solid fa-magnifying-glass" onClick={btnHandler} ></i>
                <input type="text" value={data} onChange={(e) => setData(e.target.value)} name='search' />
              </div>
              <div className='line'></div>
              <div style={{ fontSize: '48px', fontWeight: 'bold' }}>rch</div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SearchBar