import React from "react";
import { BsFillStarFill } from "react-icons/bs";
const ProgressBar = ({ rating, review }) => {
  const my_rating = `${rating * 20}%`;
  return (
    <>
      <div className="row d-flex justify-content-center align-items-center my-2">
        <div className="col-2">
          <BsFillStarFill size="25" className="text-warning" />
        </div>
        <div className="col-7">
          <div className="progress my-auto">
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: my_rating }}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div className="col-3">
          <small>{review} Review</small>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
