import React, { useState } from "react";
import RatingStar from "./RatingStar";
import Ratings from "./Ratings";
import RatingDistribution from "./RatingDistribution";
import WriteRating from "./WriteRating";

const Rating = () => {
  const [ratings, setRatings] = useState([
    {
      img: "https://toppng.com/uploads/preview/male-user-filled-icon-man-icon-115533970576b3erfsss1.png",
      name: "Vivek Gupta",
      date: "14 Dec, 2022",
      rating: "4.5",
      comment:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa natus alias eaque aliquam voluptatem, ratione quae iusto perspiciatis aliquid placeat odio aspernatur illo corporis sed sunt atque itaque facere quas.",
    },
    {
      img: "https://toppng.com/uploads/preview/male-user-filled-icon-man-icon-115533970576b3erfsss1.png",
      name: "Shyam Mishra",
      date: "14 Dec, 2022",
      rating: "4.5",
      comment:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa natus alias eaque aliquam voluptatem, ratione quae iusto perspiciatis aliquid placeat odio aspernatur illo corporis sed sunt atque itaque facere quas.",
    },
  ]);
  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center my-3">
          <div className="col-md-3">
            <RatingStar review="12" avg_rating="4.2" />
          </div>
          <div className="col-md-4">
            <RatingDistribution />
          </div>
          <div className="col-md-5">
            <WriteRating />
          </div>
        </div>
        <hr className="w-100 my-3" />
        <Ratings ratings={ratings} />
      </div>
    </div>
  );
};

export default Rating;
