import React, {  useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./hotel.css"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Slider = ({img1,img2,img3,img4}) => {
  const [ slider, setSlider] = useState([img1,img2,img3,img4]);
  return (
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        >
           {
            slider.map(
              (val,i)=>{
                return(
                    <SwiperSlide  key={i}>
                      <img src={val}/>
                    </SwiperSlide>
                )
              }
            )
           }
      </Swiper>
  );
}
export default Slider;
