import React from 'react'

const VendorIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_686_3020)">
                <path opacity="0.989" fill-rule="evenodd" clip-rule="evenodd" d="M17.5837 0C18.3128 0 19.042 0 19.7712 0C23.4516 0.776818 25.6521 3.0164 26.3727 6.71875C26.4249 8.22914 26.4249 9.73961 26.3727 11.25C25.7077 14.8578 23.5852 17.0583 20.0056 17.8516C16.3724 18.294 13.625 16.9528 11.7634 13.8281C11.2703 12.8716 10.9838 11.8559 10.904 10.7812C10.8361 9.34672 10.8621 7.91445 10.9821 6.48438C11.5346 3.64695 13.1362 1.65476 15.7868 0.507812C16.3799 0.279199 16.9789 0.109928 17.5837 0Z" fill="#F7E2AB" />
                <path opacity="0.965" fill-rule="evenodd" clip-rule="evenodd" d="M17.3481 19.375C18.3425 19.3422 19.332 19.3813 20.3169 19.4922C21.1193 19.9983 21.3406 20.6884 20.981 21.5625C20.7012 21.9397 20.4407 22.3303 20.1997 22.7344C20.1262 22.9934 20.0611 23.2538 20.0044 23.5156C20.1549 24.7142 20.3112 25.9122 20.4731 27.1094C20.1139 28.0438 19.7624 28.9813 19.4185 29.9219C18.9619 30.4081 18.4802 30.4342 17.9731 30C17.5464 29.0064 17.1819 27.9908 16.8794 26.9531C17.0624 25.63 17.1536 24.3019 17.1528 22.9688C16.9071 22.4253 16.6207 21.9045 16.2934 21.4063C16.0188 20.3898 16.3704 19.7127 17.3481 19.375Z" fill="#FFC338" />
                <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M31.8791 40.0002C23.025 40.0002 14.1708 40.0002 5.31662 40.0002C5.18821 39.9364 5.09707 39.8322 5.04318 39.6877C4.96614 34.5019 4.99218 29.3196 5.12131 24.1408C5.40112 23.421 5.90893 22.9392 6.64474 22.6955C9.06092 21.773 11.4828 20.8745 13.9104 20.0002C14.264 20.002 14.4594 20.1843 14.4963 20.547C15.8644 26.0452 17.2445 31.5399 18.6369 37.0314C20.0786 31.4467 21.4849 25.8478 22.8557 20.2345C23.1436 19.9629 23.469 19.9238 23.8322 20.1173C26.3153 21.1635 28.7893 22.2313 31.2541 23.3205C31.6508 23.6124 31.9243 23.99 32.0744 24.4533C32.1265 29.5574 32.1265 34.6616 32.0744 39.7658C31.9928 39.834 31.9277 39.9121 31.8791 40.0002Z" fill="#E01D61" />
            </g>
            <defs>
                <clipPath id="clip0_686_3020">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default VendorIcon