import React, { useState, useEffect } from 'react';
import './components/service/Service.css';
import db from './components/firebase';
import { collection, getDocs } from 'firebase/firestore';
import BlogContainer from './components/blog/BlogContainer';
import Footer from './components/footer/Footer';
import Vendors from './components/vendor/Vendors';
import Header from './components/Header';
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import VendorInfo from './components/vendor/VendorInfo';
import Home from './components/Home';
import SearchBar from './components/search/SearchBar';
import SliderContainer from './components/SliderContainer';
import BookContainer from './components/book/BookContainer';
import SingleBlog from './components/blog/SingleBlog';
import Venue from './Venue';
import MehndiArtist from './MehndiArtist';
import VenueSingle from './VenueSingle';
import MehndiArtistSingle from './MehndiArtistSingle'
import Hotel from './Hotel';
import HotelSingle from './HotelSingle';
import FIlterOption from './FIlterOption';
import FilterContainer from './components/filter/FilterContainer';
import WeddingPackage from './WeddingPackage';
import Checklist from './Checklist';
import ServiceSubCategory from './components/service/ServiceSubCategory';
import WeddingForm from './Checkbox/Wedding/WeddingForm';
import CorporateForm from './Checkbox/Corporate/CorporateForm'
import BirthdayForm from './Checkbox/Birthday/BirthdayForm'
import AnniversaryForm from './Checkbox/Anniversary/AnniversaryForm';
import MyHeader from './components/MyHeader';
const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHome, setIsHome] = useState(true);
  console.log('Go to Top', isVisible)
  const listenToScroll = () => {

    let heightToHidden = 300;

    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {

      setIsVisible(true)

    } else {

      setIsVisible(false);

    }

  }



  const [data, setData] = useState([])
  const [cat_id, setCatID] = useState(null)
  console.log('Cat Id', cat_id)
  const location = useLocation();

  console.log(useParams());
  console.log("Current Location ", location.pathname);
  const lastPart = location.pathname.split("/").pop();
  console.log('Last Part', lastPart)
  const [subCatId, setSubCatId] = useState(null);
  console.log('Wah sub Cat', subCatId)

  useEffect(() => {
    const getCategories = async () => {
      const mycollection = collection(db, 'categories');
      const data = await getDocs(mycollection);
      setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    getCategories();
  }, [])


  useEffect(() => {
    setCatID(lastPart)
  }, [lastPart])
  const handleClick = (event, sub_cat_id) => {
    setSubCatId(sub_cat_id);
  };
  const Home = () => {
    return (
      <>
        <FIlterOption />
        <FilterContainer />
      </>
    )
  }
  return (
    <>
      {
        !isVisible && <SearchBar />
      }
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        {
          data.map((data) => {
            return (
              <>
                <Route path={`${data.href}/:cat_id/`} element={<Vendors />} key={data.id} />
                <Route path={`${data.href}/:cat_id/:id`} element={<VendorInfo />} />
              </>
            )
          })
        }
        <Route path="venue" element={<Venue />} />
        <Route path="hotel" element={<Hotel />} />
        <Route path="hotel/v1/:id" element={<HotelSingle />} />
        <Route path="venue/:cat_id/v1/:id" element={<VenueSingle />} />
        <Route path="wedding-checklist" element={<WeddingForm />} />
        <Route path="anniversary-checklist" element={<AnniversaryForm />} />
        <Route path="corporate-event-checklist" element={<CorporateForm />} />
        <Route path="birthday-checklist" element={<BirthdayForm />} />
        <Route path="mehndi-artist" element={<MehndiArtist />} />
        <Route path="mehndi-artist/v1/:id" element={<MehndiArtistSingle />} />
        <Route path="blog" element={<BlogContainer />} />
        <Route path="blog/:id" element={<SingleBlog />} />
        {/*
        <Route path="vendor" element={<Home />}>
          <Route path="profile" element={<Profile /> */}
      </Routes>
      <WeddingPackage />
      <SliderContainer />
      <BookContainer />
      <Checklist />
      <BlogContainer />
      <Footer />
    </>

  );
}

export default App;