import React from 'react'

const VenueIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8953 3.2375C13.5266 3.85625 12.6453 5.13125 12.1453 5.75C11.5703 6.46875 10.1078 7.98125 9.42032 8.5625C6.95156 10.6562 4.00156 12.2062 0.982813 12.9875L0.295313 13.1687L0.276563 13.7687L0.257812 14.375H2.13906H4.01406L4.74531 14.0812C6.38281 13.425 8.20156 12.4062 9.22657 11.5625C11.2016 9.94375 12.8016 7.26875 13.8578 3.81875C14.0141 3.31875 14.1266 2.90625 14.1203 2.9C14.1078 2.8875 14.0078 3.04375 13.8953 3.2375Z" fill="#E72E77" />
            <path d="M16.2656 2.8875C16.2656 3.0125 16.8156 4.7 17.1031 5.43125C18.3719 8.68125 19.9281 10.8 22.1031 12.2375C23.0531 12.8687 24.5094 13.625 25.6594 14.0812L26.3906 14.375H28.2656H30.1469L30.1281 13.7687L30.1094 13.1625L29.8906 13.1125C26.6781 12.3312 23.6344 10.7875 21.1094 8.65625C20.2406 7.91875 18.8844 6.5375 18.2094 5.6875C17.7656 5.125 16.8656 3.81875 16.4031 3.0625C16.3281 2.94375 16.2656 2.8625 16.2656 2.8875Z" fill="#E72E77" />
            <path d="M15.1406 3.28125C15.1406 3.44375 14.4594 5.475 14.1406 6.2375C13.2531 8.4 12.2094 10.0812 10.9219 11.425C9.95938 12.425 8.82813 13.2687 7.42188 14.0062L6.73438 14.375H15.2031H23.6719L23.0344 14.0375C20.6406 12.775 18.9406 11.1875 17.5969 8.9375C17.1656 8.2125 16.6344 7.14375 16.2656 6.2375C15.9469 5.475 15.2656 3.44375 15.2656 3.28125C15.2656 3.23125 15.2406 3.1875 15.2031 3.1875C15.1719 3.1875 15.1406 3.23125 15.1406 3.28125Z" fill="#FFEE92" />
            <path d="M0.309375 15.4813C0.284375 15.5 0.265625 15.8688 0.265625 16.2938V17.0625H15.2031H30.1469L30.1281 16.2625L30.1094 15.4688L15.2281 15.45C7.04687 15.4438 0.328125 15.4563 0.309375 15.4813Z" fill="#FFC338" />
            <path d="M0.828125 24.5312V30.9375H1.35938H1.89063V24.5312V18.125H1.35938H0.828125V24.5312Z" fill="#FFEE92" />
            <path d="M3.32812 18.1626C3.32812 18.2438 3.77813 18.8063 4.01563 19.0188C4.58438 19.5376 5.26563 19.8001 5.82188 19.7188C6.17188 19.6688 6.67813 19.4438 6.99688 19.2063C7.22813 19.0313 7.89063 18.2688 7.89063 18.1751C7.89063 18.1501 6.86563 18.1251 5.60938 18.1251C4.35313 18.1251 3.32812 18.1438 3.32812 18.1626Z" fill="#FFC338" />
            <path d="M9.80781 18.3188C9.87031 18.425 10.0953 18.6875 10.3078 18.9063C11.5516 20.1626 12.9891 19.9626 14.1703 18.3563L14.3391 18.125H12.0203H9.69531L9.80781 18.3188Z" fill="#FFC338" />
            <path d="M16.1406 18.1812C16.1406 18.2562 16.3656 18.55 16.6969 18.8937C17.7531 19.9937 19.0344 20 20.1094 18.9062C20.3156 18.6937 20.5344 18.4312 20.5969 18.3187L20.7094 18.125H18.4281C17.1719 18.125 16.1406 18.15 16.1406 18.1812Z" fill="#FFC338" />
            <path d="M22.5156 18.1624C22.5156 18.1811 22.6406 18.3561 22.7906 18.5561C23.6156 19.6249 24.6656 19.9811 25.6531 19.5186C25.8844 19.4124 26.1969 19.2061 26.3594 19.0561C26.6281 18.8124 27.0781 18.2499 27.0781 18.1624C27.0781 18.1436 26.0531 18.1249 24.7969 18.1249C23.5406 18.1249 22.5156 18.1436 22.5156 18.1624Z" fill="#FFC338" />
            <path d="M28.5156 24.5312V30.9375H29.0469H29.5781V24.5312V18.125H29.0469H28.5156V24.5312Z" fill="#FFEE92" />
            <path d="M8.62813 18.9813C8.16563 19.6813 7.35938 20.3375 6.60938 20.625C6.07188 20.8313 5.14063 20.8313 4.60938 20.625C4.13438 20.4438 3.65313 20.1375 3.25938 19.7688L2.95312 19.4813V25.2125V30.9375H15.2031H27.4531V25.2063V19.475L27.1594 19.7625C26.7906 20.1188 26.2906 20.4375 25.7969 20.625C25.2656 20.8313 24.3344 20.8313 23.7969 20.625C23.0781 20.35 22.4031 19.825 21.8844 19.125C21.7656 18.9688 21.6469 18.8375 21.6219 18.825C21.5969 18.8188 21.4219 19 21.2344 19.2438C20.3469 20.375 19.0844 20.9563 17.9406 20.7625C17.2844 20.65 16.6781 20.3313 16.1281 19.8188C15.8719 19.575 15.5656 19.2375 15.4469 19.0688C15.3281 18.8938 15.2219 18.7563 15.2031 18.7563C15.1844 18.7563 15.0781 18.8938 14.9594 19.0688C14.8406 19.2375 14.5344 19.575 14.2781 19.8188C13.1906 20.8313 11.9094 21.0688 10.6844 20.4813C10.1156 20.2125 9.62188 19.8063 9.19688 19.2688C9.01563 19.0375 8.84688 18.8188 8.82813 18.7938C8.80313 18.7688 8.71563 18.85 8.62813 18.9813ZM14.5844 22.1125C14.8031 22.2188 15.0344 22.3438 15.0969 22.3875C15.1844 22.45 15.2219 22.45 15.3156 22.3875C15.3719 22.3438 15.6031 22.2188 15.8281 22.1063C16.1906 21.9313 16.2906 21.9063 16.7656 21.9063C17.2219 21.9063 17.3469 21.9313 17.6469 22.0813C18.5531 22.525 19.0781 23.6563 18.8969 24.7688C18.7594 25.5875 18.5156 25.9688 17.3781 27.1688C16.6031 27.9875 16.1781 28.5625 15.8219 29.2688C15.5469 29.8063 15.3844 29.925 15.0469 29.8313C14.8969 29.7875 14.8094 29.6813 14.5719 29.2438C14.1594 28.4625 13.7969 27.9813 12.9406 27.0813C11.8719 25.9563 11.5844 25.475 11.4781 24.6438C11.4281 24.2375 11.5406 23.5688 11.7281 23.15C12.2406 22.0188 13.4844 21.575 14.5844 22.1125Z" fill="#E01D61" />
            <path d="M13.1561 23.0562C12.5249 23.4562 12.3499 24.5187 12.7936 25.2812C12.8874 25.4312 13.3311 25.9499 13.7936 26.4374C14.2499 26.9187 14.7499 27.4937 14.8999 27.7187C15.0436 27.9374 15.1811 28.1187 15.1999 28.1187C15.2186 28.1187 15.3499 27.9437 15.4999 27.7187C15.6499 27.4999 16.1436 26.9249 16.6061 26.4374C17.6186 25.3624 17.8436 24.9937 17.8499 24.3749C17.8499 23.7624 17.5936 23.2374 17.1874 23.0249C16.7999 22.8249 15.9811 23.0749 15.7186 23.4812C15.6624 23.5687 15.5186 23.6812 15.4061 23.7249C15.2311 23.7999 15.1686 23.7999 14.9936 23.7249C14.8811 23.6812 14.7374 23.5687 14.6811 23.4812C14.3999 23.0499 13.5436 22.8124 13.1561 23.0562Z" fill="#E72E77" />
        </svg>
    )
}

export default VenueIcon