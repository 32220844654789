import React from 'react'
import HotelPreview from '../HotelReview/HotelPreview'
import { useParams } from 'react-router-dom';

const VendorInfo = () => {
  let { id } = useParams();
  return (
    <>
    <HotelPreview id={id}/>
    </>
  )
}

export default VendorInfo