import React from 'react'
import './FilterOption.css'
import cal from './cal.png'
import DialogSelect from './DialogSelect'
import PreferLocation from './PreferLocation'
const FIlterOption = () => {
    return (
        <div className='container'>
            <div className='row bg-white'>
                <div className='col-md-12'>
                    <div className='filterContainer'>
                        <div className='filterBox'>
                            <div className='upper'>
                                <div className='left_side'>
                                    <span className='filter_title'>City</span>
                                </div>
                                <div className='right_side'>
                                    <div className='custom_box'>
                                        <span className='filter_title'>near me</span>
                                    </div>
                                    <div className='custom_box'>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='lower'>
                                {/* <span className='filter_sub_title'>Lucknow</span> */}
                                <DialogSelect />
                            </div>
                        </div>
                        <div className='filterBox'>
                            <div className='upper'>
                                <span className='filter_title'>Preferred Location</span>
                            </div>
                            <div className='lower'>
                                {/* <span className='filter_sub_title'>Faizabad Road</span> */}
                                <PreferLocation />
                            </div>
                        </div>
                        <div className='filterBox filterCustomBox'>
                            <div className='left'>
                                <div className='top'>
                                    <span className='filter_title'>Check Availability</span>
                                </div>
                                <div className='bottom'>
                                    <span className='filter_sub_title'>3 Mar ‘22</span>
                                </div>
                            </div>
                            <div className='right'>
                                <img src={cal} className="calendar" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FIlterOption