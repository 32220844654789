import React from "react";
import "./Filter.css";
import { BsChevronDoubleDown } from "react-icons/bs";
const FilterContainer = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2 mx-auto text-center d-flex justify-content-around">
          <div className="icon">
            <BsChevronDoubleDown className="font-weight-bold" />
          </div>
          <div className="text">
            <h5>Filter More</h5>
          </div>
          <div className="icon">
            <BsChevronDoubleDown className="font-weight-bold" />
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col-lg-8 mx-auto">
          <div class="filter_container">
            <ul>
              <li>Price</li>
              <li>Rooms Required</li>
              <li>Urgent Hotel </li>
              <li>Premium Hotel </li>
              <li>Ratings</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterContainer;
