import React from "react";
import Book from "../book/Book";
import "./Book.css";
const BookContainer = () => {
  return (
    <div className="container">
      <div className="row py-3 mt-3">
        <h5 style={{ fontWeight: '700' }}>I Want To Book</h5>
      </div>
      <div class="row">
        <Book />
      </div>
    </div>
  );
};

export default BookContainer;
