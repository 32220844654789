import React, { useState, useEffect } from "react";
import "./hotel.css";
import db from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { IoIosShareAlt } from "react-icons/io";
import { FaPenNib } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import Rating from "../rating/Rating";
import HotelForm from './HotelForm';
import Nonveg from './../../nonveg.png'
import Veg from './../../veg.png';
import CustomImageBox from "./CustomImageBox";

const HotelPreview = ({ id }) => {
  const [data, setData] = useState([]);
  console.log(data)
  const singleData = async () => {
    const mycollection = collection(db, "merchants");
    const data = await getDocs(mycollection);
    setData(
      data.docs
        .filter((doc) => doc.id === id)
        .map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };
  const cityFinder = async () => {
    const mycollection = collection(db, "cities");
    const res = await getDocs(mycollection);
    console.log('cities', res.data())
  }
  useEffect(() => {
    singleData();
    cityFinder()
  }, [id]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])
  return (
    <>
      {
        data.map((data, i) => {
          return (
            <div className='container ' key={i}>
              <div className='row'>
                <div className='col-md-7'>
                  <CustomImageBox
                    img1={data?.PreviousWork[0]}
                    img2={data?.PreviousWork[1]}
                    img3={data?.PreviousWork[2]}
                    img4={data?.PreviousWork[3]}
                  />
                  <div className='col-md-12'>
                    <div className="btn-group form-control p-0" role="group">
                      <button type="button" className="btn btn-outline-dark ">
                        <FiHeart /> Save
                      </button>
                      <button type="button" className="btn btn-outline-dark">
                        <FaPenNib /> Write a review
                      </button>
                      <button type="button" className="btn btn-outline-dark">
                        <IoIosShareAlt /> Share
                      </button>
                    </div>
                  </div>
                </div>

                <div className='col-md-5'>

                  <div className='head_section'>
                    <div>
                      <h4 className='heading'>{data.business_name}</h4>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div>
                      <FaMapMarkerAlt className='cln_icon' />
                      {data.vendorProfile.city_id}
                    </div>
                    <div className="d-flex justify-content-around">
                      <a href={`tel:${data.vendorProfile.mobno}`} className="btn" style={{ backgroundColor: '#40B0FA', color: '#fff', fontWeight: 'bold' }}> Phone Number </a>
                    </div>


                    {/* <span><a href={`tel:${data.vendorProfile.mobno}`} className="btn" style={{ backgroundColor: '#40B0FA', color: '#fff', fontWeight: 'bold' }}> Phone Number </a></span> */}
                  </div>
                  <p className="p-0 m-0">{data.vendorProfile.street}</p>
                  {/* <button className='cln_btn'>Check Availability &nbsp;
                    <BiCalendarWeek className='cln_icon text-white' />
                  </button> */}
                  <div className="">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className='star_rate' >
                        <div className="star">
                          <BsStarFill className='small' />
                          <BsStarFill className='small' />
                          <BsStarFill className='small' />
                          <BsStarFill className='small' />
                        </div>
                        <b className="mx-2">Good</b>
                      </div>
                      <div>
                        {`${data.thingsToKnow.avg_rating || 4.5} (${data.thingsToKnow.review || 17} Reviews)`}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-2 pricing__section">
                    <p>Veg  Starting <img src={Veg} alt="Veg Price Tag" alt="Event Planet" /> &#8377;{data.thingsToKnow?.nonVegPrice}</p>
                    <p>Non Veg Starting  <img src={Nonveg} alt="Veg Price Tag" alt="Event Planet" /> &#8377;{data.thingsToKnow?.nonVegPrice}</p>
                  </div>
                  <div className='center_div'>
                    <div className='row btn_style'>
                      <div className='col-md-6 col-6'>
                        <button style={{ fontSize: '14px' }}>Block Your Date @ &#8377; 5000</button>
                      </div>
                      <div className='col-md-6 col-6'>
                        <button style={{ fontSize: '14px' }}>Book Venue</button>
                      </div>
                    </div>
                  </div>
                  <HotelForm />
                </div>
              </div>
              <div className='row d-flex justify-content-center ' >
                <div className='col-md-12'>
                  <h4>Things To Know</h4>
                  <div className="row">

                    <div className="col-md-12">
                      <div className="card shadow-sm">
                        <div className="card-body">
                          <table className="table table-bordered ttk_table" style={{ fontSize: '10px', border: '1px solid #fff' }}>
                            <tr>
                              <th style={{ width: '25%' }}>Working Since</th>
                              <th style={{ width: '25%' }}>Parking</th>
                              <th style={{ width: '25%' }}>Parking Space</th>
                              <th style={{ width: '25%' }}>Veg Price</th>
                            </tr>
                            <tr>
                              <td>
                                {
                                  data.thingsToKnow.workingSince
                                    ?.map((item, index) => {
                                      return (
                                        <span key={index}>{item.value} </span>
                                      )
                                    })
                                }
                              </td>
                              <td>
                                {
                                  data.thingsToKnow.parking
                                    ?.map((item, index) => {
                                      return (
                                        <span key={index}>{item.value} </span>
                                      )
                                    })
                                }
                              </td>
                              <td>{data.thingsToKnow?.parkingSize}</td>
                              <td>&#8377; {data.thingsToKnow?.vegPrice}</td>
                            </tr>
                            <tr>
                              <th>Non Veg Price</th>
                              <th>Small Pary Venue</th>
                              <th>Avg Room Price</th>
                              <th>Room Count</th>
                            </tr>
                            <tr>
                              <td>&#8377; {data.thingsToKnow.nonVegPrice}</td>
                              <td>
                                {
                                  data.thingsToKnow.smallPartiesVenue
                                    ?.map((item, index) => {
                                      return (
                                        <p key={index}><i className='fa fa-check text-success' />
                                          <span key={index}>{item.value} </span></p>
                                      )
                                    })
                                }
                              </td>
                              <td>&#8377; {data.thingsToKnow?.avgRoomPrice}</td>

                              <td>{data.thingsToKnow?.roomCount}</td>
                            </tr>
                            <tr>
                              <th>Space</th>
                              <th>Catering Policy</th>
                              <th>Decor Policy</th>
                              <th>Alcohol Policy</th>
                            </tr>
                            <tr>
                              <td>
                                {
                                  data.thingsToKnow.space
                                    ?.map((item, index) => {
                                      return (
                                        <p key={index}><i className='fa fa-check text-success' />
                                          <span key={index}>{item.value} </span></p>
                                      )
                                    })
                                }
                              </td>
                              <td>
                                {
                                  data.thingsToKnow.catering
                                    ?.map((item, index) => {
                                      return (
                                        <p key={index}><i className='fa fa-check text-success' />
                                          <span key={index}>{item.value} </span></p>
                                      )
                                    })
                                }
                              </td>
                              <td>
                                {
                                  data.thingsToKnow.decorPolicy
                                    ?.map((item, index) => {
                                      return (
                                        <p key={index}><i className='fa fa-check text-success' />
                                          <span key={index}>{item.value} </span></p>
                                      )
                                    })
                                }
                              </td>
                              <td>
                                {
                                  data.thingsToKnow.alcoholPolicy
                                    ?.map((item, index) => {
                                      return (
                                        <p key={index}><i className='fa fa-check text-success' />
                                          <span key={index}>{item.value} </span></p>
                                      )
                                    })
                                }
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-3" style={{ textAlign: 'justify' }}
                    dangerouslySetInnerHTML={{ __html: data.vendorProfile?.introduction }}
                  />
                </div>
                <div className='col-md-4'>

                </div>
              </div>
              <Rating />
            </div>
          )
        })
      }
    </>
  );
};
export default HotelPreview;
