import React from 'react'
import './CustomImageBox.css'
const CustomImageBox = ({ img1, img2, img3, img4 }) => {
    return (
        <div className='main_container'>
            <div className='large_image'>
                <img src={img1} alt="Event Planet" />
            </div>
            <div className='small_image'>
                <img src={img2} alt="Event Planet" />
                <img src={img3} alt="Event Planet" />
                <img src={img4} alt="Event Planet" />
            </div>
        </div>
    )
}

export default CustomImageBox