import React from "react";
import { BsFillStarFill } from "react-icons/bs";
const Ratings = ({ ratings }) => {
  return (
    <>
      <div className="container">
        <div className="row">
          {ratings.map((data, i) => {
            return (
              <div className="col-md-12" key={i}>
                <div className="ratings d-flex">
                  <div>
                    <img
                      src="https://toppng.com/uploads/preview/male-user-filled-icon-man-icon-115533970576b3erfsss1.png"
                      alt={data.name}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex">
                      <div className="name">
                        <h6>{data.name}</h6>
                      </div>
                      <div className="date">
                        <span>{data.date}</span>
                      </div>
                    </div>
                    <div className="main_rating">
                      <div>
                        <BsFillStarFill className="star" />
                        <BsFillStarFill className="star" />
                        <BsFillStarFill className="star" />
                        <BsFillStarFill className="star" />
                      </div>
                      <div>
                        <h5>{data.rating}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="mt-2 para">{data.comment}</p>
                  <hr />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Ratings;
