import React from 'react'

const GovernmentIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1602_2850)">
                <path d="M15.2969 0.89961C14.8406 1.04961 14.5156 1.26836 14.1531 1.65586C13.1844 2.69336 13.2219 4.28086 14.2344 5.29336C14.6156 5.68086 14.9344 5.86836 15.4406 6.00586C15.7594 6.08711 15.7406 6.09336 14.7344 6.09961C14.0906 6.09961 13.5406 6.13711 13.2656 6.19336C12.1281 6.43086 11.0656 7.25586 10.5531 8.28711C10.2469 8.91836 10.1094 9.49961 10.1094 10.2371V10.7871H16.1094H22.1094V10.2371C22.1031 9.04336 21.7219 8.08711 20.9219 7.28086C20.5594 6.91836 20.3281 6.74336 19.9219 6.54336C19.1969 6.18711 18.7281 6.10586 17.4844 6.09961C16.4656 6.09336 16.4594 6.09336 16.7906 5.99961C18.0969 5.64961 18.9594 4.33086 18.7344 3.03086C18.5781 2.13086 17.9094 1.27461 17.1281 0.968359C16.6281 0.77461 15.7719 0.74336 15.2969 0.89961Z" fill="#FEC43E" />
                <path d="M7.42188 13.0996V14.0996H16.1094H24.7969V13.0996V12.0996H16.1094H7.42188V13.0996Z" fill="#E01F62" />
                <path d="M9.46562 15.4558C9.44062 15.4746 9.42188 16.6058 9.42188 17.9621V20.4246L9.75937 20.7933C10.1469 21.2246 10.5031 21.8871 10.6531 22.4746C10.7906 23.0121 10.7844 23.8871 10.6406 24.4121C10.4656 25.0496 10.4594 25.0308 10.9531 25.2371C11.1969 25.3371 11.4469 25.4058 11.5156 25.3871C11.6531 25.3621 12.3469 25.0746 12.3906 25.0308C12.4031 25.0121 12.3594 24.8183 12.2844 24.5996C12.1781 24.2746 12.1469 24.0371 12.1469 23.4433C12.1469 22.7808 12.1656 22.6371 12.3219 22.1933C12.5469 21.5371 12.8406 21.0683 13.2969 20.6183C14.8906 19.0621 17.3656 19.0683 18.9281 20.6308C19.3906 21.0871 19.6719 21.5433 19.8969 22.1933C20.0531 22.6371 20.0719 22.7808 20.0719 23.4433C20.0719 24.0371 20.0406 24.2746 19.9344 24.5996C19.8594 24.8183 19.8156 25.0121 19.8281 25.0308C19.8719 25.0746 20.5656 25.3621 20.7031 25.3871C20.7719 25.4058 21.0281 25.3371 21.2656 25.2371C21.7594 25.0308 21.7531 25.0496 21.5781 24.4121C21.4156 23.8246 21.4281 22.8871 21.6094 22.3183C21.7906 21.7371 22.1031 21.1871 22.4844 20.7683L22.7969 20.4246L22.7844 17.9308L22.7656 15.4433L16.1344 15.4246C12.4906 15.4183 9.48437 15.4308 9.46562 15.4558Z" fill="#E01F62" />
                <path d="M5.92188 20.9121C4.37188 21.4871 3.67813 23.1746 4.40313 24.6246C4.72187 25.2559 5.40938 25.8184 6.10313 26.0121C6.37188 26.0809 6.31563 26.0871 5.39062 26.0934C4.77813 26.0996 4.22187 26.1371 3.95312 26.1934C2.49688 26.4996 1.23438 27.7246 0.896875 29.1621C0.834375 29.4309 0.796875 29.8871 0.796875 30.4871V31.4121H4.79063H8.78438L8.80937 30.2684C8.84062 29.2746 8.86563 29.0621 9.00938 28.5809C9.22188 27.8746 9.44688 27.4309 9.89062 26.8559C10.0844 26.6059 10.2344 26.3934 10.2219 26.3809C10.2094 26.3684 10.0469 26.3121 9.85938 26.2559C9.60313 26.1746 9.21562 26.1371 8.32812 26.1121C7.15938 26.0871 7.14687 26.0809 7.48438 25.9934C8.20312 25.8121 8.82812 25.2809 9.17813 24.5621C9.37813 24.1496 9.39062 24.0996 9.39062 23.4434C9.39062 22.7996 9.37813 22.7309 9.19063 22.3371C8.91563 21.7558 8.50938 21.3371 7.93438 21.0558C7.50938 20.8433 7.41563 20.8183 6.86562 20.8058C6.38438 20.7871 6.19688 20.8121 5.92188 20.9121Z" fill="#FFC338" />
                <path d="M15.2969 20.8996C14.8406 21.0496 14.5156 21.2684 14.1531 21.6559C13.1844 22.6934 13.2219 24.2809 14.2344 25.2934C14.6156 25.6809 14.9344 25.8684 15.4406 26.0059C15.7594 26.0871 15.7406 26.0934 14.7344 26.0996C13.4906 26.1059 13.0219 26.1871 12.2969 26.5434C11.2656 27.0559 10.4406 28.1184 10.2031 29.2559C10.1469 29.5184 10.1094 30.0434 10.1094 30.5496V31.4121H16.1094H22.1094V30.5496C22.1094 30.0434 22.0719 29.5121 22.0156 29.2559C21.7844 28.1371 20.9469 27.0496 19.9219 26.5434C19.1969 26.1871 18.7281 26.1059 17.4844 26.0996C16.4656 26.0934 16.4594 26.0934 16.7906 25.9996C18.0969 25.6496 18.9594 24.3309 18.7344 23.0309C18.5781 22.1309 17.9094 21.2746 17.1281 20.9684C16.6281 20.7746 15.7719 20.7434 15.2969 20.8996Z" fill="#FFC338" />
                <path d="M24.6445 20.8934C23.8882 21.1684 23.3632 21.6309 23.032 22.3371C22.8445 22.7309 22.832 22.7996 22.832 23.4434C22.832 24.0996 22.8445 24.1559 23.0445 24.5684C23.4007 25.2871 24.0257 25.8121 24.7382 25.9934C25.0757 26.0809 25.057 26.0871 23.8632 26.1121C22.9195 26.1371 22.5757 26.1684 22.332 26.2559C22.1632 26.3121 22.0132 26.3684 22.0007 26.3809C21.9882 26.3934 22.1382 26.6059 22.3382 26.8559C22.7382 27.3746 23.107 28.1121 23.2695 28.7246C23.3445 29.0059 23.3882 29.4809 23.407 30.2684L23.4382 31.4121H27.432H31.4257V30.4871C31.4257 29.4121 31.3382 28.9684 31.0007 28.2871C30.6632 27.5996 29.9507 26.8934 29.2382 26.5434C28.5195 26.1934 28.0382 26.1059 26.832 26.0934C25.907 26.0871 25.8507 26.0809 26.1195 26.0121C26.5695 25.8871 27.0257 25.6059 27.357 25.2559C28.6132 23.9309 28.232 21.8434 26.5882 21.0371C26.2007 20.8434 26.0882 20.8184 25.557 20.8059C25.1007 20.7871 24.882 20.8121 24.6445 20.8934Z" fill="#FFC338" />
            </g>
            <defs>
                <clipPath id="clip0_1602_2850">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default GovernmentIcon