import { BsFillStarFill } from "react-icons/bs";
import React from "react";
import "./Rating.css";
import { Rating } from "@mui/material";
import { useState } from "react";

const RatingStar = ({ review, avg_rating }) => {
  const [rating, setRating] = useState(0);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="ratingstar">
            <h4>{review} Reviews</h4>
            <h3>
              {avg_rating}
              <br />
              <BsFillStarFill className="starfill" />
              <BsFillStarFill className="starfill" />
              <BsFillStarFill className="starfill" />
              <BsFillStarFill className="starfill" />
            </h3>

            <div className="starborder">
              <Rating
                name="simple-controlled"
                value={rating}
                onChange={(e, newValue) => {
                  setRating(newValue);
                }}
                size="large"
                // onClick={btnHandler}
              />
              <p> {`You are rated ${rating}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingStar;
