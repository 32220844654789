import React, { useState, useEffect } from 'react'
import db from './components/firebase';
import { query, where, collection, getDocs } from 'firebase/firestore';
import VenueBox from './components/venue/VenueBox';
function Venue() {
    const serviceID = "Ztaeuwsk7kHIATCopKqB";
    const [data, setData] = useState([])
    console.log(data)
    const getCategories = async (serviceID) => {
        const mycollection = collection(db, 'merchants');
        let q = query(mycollection, where('serviceId', '==', serviceID));
        const data = await getDocs(q);
        setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        console.log("INITIAL PROCESS")
        getCategories(serviceID)
    }, [serviceID])
    return (
        <div class="container">
            <div className="row">
                {
                    data.map((item, i) => {
                        return (
                            <VenueBox
                                key={i}
                                main_image={item.profilePicture.image}
                                small_1={item.PreviousWork[0]}
                                small_2={item.PreviousWork[1]}
                                small_3={item.PreviousWork[2]}
                                title={item.business_name}
                                fixedCapacity={item.serviceAndPricing.fixedCapacity}
                                floatingCapacity={item.serviceAndPricing.floatingCapacity}
                                avg_rating="0"
                                review="0"
                                location={item.contactInfo.businessAddress}
                                vegPrice={item.serviceAndPricing.vegPrice}
                                nonVegPrice={item.serviceAndPricing.nonVegPrice}
                                id={item.id}
                                cat_id={item.serviceId}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Venue