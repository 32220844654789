import React, { useState, useEffect } from "react";
import db from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import Blog from "./Blog";
import axios from "axios";
const BlogContainer = () => {
  const [data, setData] = useState([]);
  const link = 'https://eventplanet.in//api/auth/blog';
  const [blog, setBlog] = useState([]);
  const blogFinder = async () => {
    const res = await axios.get(link);
    setBlog(res.data.data)
  }
  useEffect(() => {
    const getCategories = async () => {
      const mycollection = collection(db, "blog");
      const data = await getDocs(mycollection);
      setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    getCategories();
    blogFinder();
  }, []);


  return (
    <div className="container">
      <div className="row">
        <h4>Blogs</h4>
      </div>
      <div className="row my-3">
        {blog.filter((blogs, idx) => idx < 3).map((blogs, i) => {
          return (
            <Blog
              id={blogs.id}
              title={blogs.title}
              desc={blogs.description}
              img={blogs.image}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BlogContainer;
