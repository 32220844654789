import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from "axios";
const SingleBlog = () => {
  let { id } = useParams();
  const link = `https://eventplanet.in//api/auth/blog/${id}`;
  const [blog, setBlog] = useState([]);
  const blogFinder = async () => {
    const res = await axios.get(link);
    setBlog(res.data.data)
  }
  useEffect(() => {
    blogFinder()
  }, [id]);
  return (
    <div className='container'>
      <div className='row'>
        {
          blog.map((data) => {
            return (
              <div className='col-md-8 mx-auto'>
                <img src={data.image} alt={data.title} className="img-fluid" />
                <h3>{data.title}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default SingleBlog