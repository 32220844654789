import React, { useState } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Book.css'
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex", marginLeft: '-20px', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', color: '#000', fontSize: '22px', background: "#fff", boxShadow: '0 3px 7px rgba(0,0,0,0.1)' }}
      onClick={onClick}
    >
      <i className='fa fa-arrow-left' />
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex", marginRight: '-20px', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', marginTop: '10px', color: '#000', fontSize: '22px', background: "#fff", boxShadow: '0 3px 7px rgba(0,0,0,0.1)' }}
      onClick={onClick}
    >
      <i className='fa fa-arrow-right' />
    </div>
  );
}


const Book = () => {
  const [bookData, setBookData] = useState([
    {
      title: 'Photographer',
      src: "https://cdn0.weddingwire.in/article/9086/3_2/960/jpg/16809-creative-wedding-photography-avinash-dhoundhiyal-photography-lead-image.jpeg"
    },
    {
      title: 'Makeup Artist',
      src: "https://www.southindiafashion.com/wp-content/uploads/2019/08/south-indian-bridal-makeup-artists.jpg"
    },
    {
      title: 'Bridal Designer',
      src: "https://img.perniaspopupshop.com/wysiwyg/WEDDING/Pheras_24-06-22.jpg"
    },
    {
      title: 'A planner',
      src: "https://image.wedmegood.com/resized/540X/uploads/member/435743/1610086032__DSC4074.jpg?crop=9,186,2030,1142"
    },
    {
      title: "Choreographers",
      src: "https://media.weddingz.in/images/b55f2149323d35fb54048ba0e1159e32/top-5-wedding-choreographers-in-delhi-who-can-wow-you-with-their-moves.jpg"
    },
    {
      title: 'Decorators',
      src: 'https://media.weddingz.in/images/34e7b71ea8210f947af0efe2b79b5d95/top-five-wedding-decorators-in-delhi-from-empty-spaces-to-wedding-dreams.jpg'
    },
    {
      title: 'Catering',
      src: 'https://shaadiwish.com/blog/wp-content/uploads/2018/02/6-10.jpg'
    },
    {
      title: 'Mehendi Artist',
      src: 'https://image.wedmegood.com/resized/540X/uploads/member/1879/1671963990_Screenshot_2022_02_11_17_26_15_45_1c337646f29875672b5a61192b9010f9__01.jpg?crop=0,587,1080,608'
    }
  ])
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <Slider {...settings} style={{ marginBottom: '10px' }}>
            {bookData.map((val, i) => {
              return (
                <Link to='#' className='my__href'>
                  <div className="book_item" key={i}>
                    <div className="book_image">
                      <img src={val.src} alt="" />
                    </div>
                    <div className="book_title">{val.title}</div>
                  </div>
                </Link>
              );
            })}


          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Book