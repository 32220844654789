import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Dialog, DialogActions, FormControl, Select, DialogContent, DialogTitle, InputLabel, OutlinedInput } from '@mui/material';


const DialogSelect = () => {
    const [open, setOpen] = React.useState(false);
    const [city, setCity] = React.useState('');

    const handleChange = (e) => {
        setCity(e.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const [data, setData] = useState(['Lucknow', 'delhi'])

    const handleClose = (reason?: String) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    return (
        <div>
            <Button onClick={handleClickOpen} sx={{ color: 'text.primary', fontWeight: 'bold', size: '40px' }}>{city ? city : ' Select City'}</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Fill the form</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 320 }}>
                            <InputLabel htmlFor="demo-dialog-native">City</InputLabel>
                            <Select
                                native
                                value={city}
                                onChange={handleChange}
                                input={<OutlinedInput label="City" id="demo-dialog-native" />}
                            >
                                <option aria-label="None" value="Select City"></option>
                                {
                                    data.map((val) => {
                                        return (
                                            <option value={val}>{val}</option>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default DialogSelect;