import React from 'react'
import './checkbox.css'
const Inputform = (props) => {
  return (

    <div className='col-md-6'>


      <div class="mb-4">
        <label for="exampleFormControlInput1" class="form-label title">{props.title}</label>
        <input type={props.type} class="form-control" id="exampleFormControlInput1" placeholder={props.placeholder} pattern={props.pattern} />
      </div>

    </div>
  )
}

export default Inputform