import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { query, where, collection, getDocs } from 'firebase/firestore';
import Img1 from "./images/img1.png";
import db from "./firebase";
import { Link } from "react-router-dom";

// import db from '../firebase';
const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        centerMode: true,
        centerPadding: 10,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
  ],
};
const Card = ({ src, title, description, price, id }) => {
  return (
    <Link to={`mehndi-artist/v1/${id}`} style={{ textDecoration: 'none', color: "#000" }}>
      <div className="col-lg-12 col-md-12 col-12">
        <div className="card m-2" style={{ boxShadow: '0 3px 8px rgba(0,0,0,0.2)' }}>
          <div className="card-header p-0">
            <img src={src} className="img-fluid" style={{ height: '180px' }} />
          </div>
          <div className="card-body p-2">
            <h6 style={{ fontWeight: 'bold' }}>{title}</h6>
            <p className="text-color-1"><i className="fa fa-map-marker"></i> {description.charAt(0).toUpperCase() + description.slice(1).toLowerCase()}</p>
            <p style={{ fontWeight: 'bold' }}>
              &#x20B9;<span>{price}</span> onwords
            </p>
          </div>
        </div>
      </div>
    </Link >
  )
}
const SliderContainer = () => {

  const [data, setData] = useState([]);
  const getCategories = async () => {
    const mycollection = collection(db, 'merchants');
    let q = query(mycollection, where('serviceId', '==', 'Q4540ZvBuGX9HcOkRruR'));
    const data = await getDocs(q);
    console.log(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  }
  useEffect(() => {
    getCategories()
  }, [])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"

    });

  }, [])
  return (
    <div className="container">
      <div className="row">
        <h4 style={{ fontWeight: 'bold' }}>Spotlight Vendors</h4>
      </div>
      <div className="row">
        <Slider {...settings}>
          {
            data.map((item) => {
              return (
                <Card src={item.profilePicture?.image} title={item?.business_name} description={`${item?.contactInfo.city}, ${item?.contactInfo.pincode} `} price={item?.serviceAndPricing?.bridalMehndiPrice} key={item.id} id={item.id} />
              )
            })
          }
        </Slider>
      </div >
    </div >
  );
};

export default SliderContainer;
