import React from 'react'
import underline from './../underline.png'
const HeadLine = () => {
    return (
        <div className='text-center'>
            <center><img src={underline} style={{ width: '200px', display: 'block', objectFit: 'contain' }} /></center>
        </div>
    )
}

export default HeadLine