import React from 'react';
import { useState, useEffect } from 'react';
import BirthdayChecklist from './BirthdayChecklist';
import Inputform from '../Inputform';
import HeadLine from './../HeadLine'



function BirthdayForm() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"

    });

  }, [])
  const [input, setInput] = useState([
    {
      title: ' Enter Name',
      type: 'text',
      placeholder: 'Enter your name'
    },
    {
      title: 'Enter Email',
      type: 'email',
      placeholder: 'Enter your email'
    },
    {
      title: 'Enter Mobile Number',
      type: 'number',
      placeholder: 'Enter your Mobile Number'
    },
    {
      title: 'Enter Address',
      type: 'address',
      placeholder: 'Enter your Address'
    }

  ])
  const [data, setData] = useState([
    {
      title: 'Photographer',
      options: [
        { value: 'Tradistiona Photo & Video', label: 'Tradistiona Photo & Video' },
        { value: 'Cinematic Video', label: 'Cinematic Video' },
        { value: 'Candid Shoot', label: 'Candid Shoot' },
        { value: 'Pre Wedding Shooting', label: 'Pre Wedding Shooting' },
        { value: 'No Requirement', label: 'No Requirement' },
      ]
    },
    {
      title: 'Planing & Decor',
      options: [
        { value: 'Decoration', label: 'Decoration' },
        { value: 'Vibe & Theme', label: 'Vibe & Theme' },
        { value: 'Color scheme', label: 'Color scheme' },
      ]
    },
    {
      title: 'Food ',
      options: [
        { value: 'Veg Food Catering', label: 'Veg Food Catering' },
        { value: ' Non-Veg Food Catering', label: 'Non-Veg Food Catering' },
        { value: ' Veg & Non-Veg Food Catering', label: 'Veg & Non-Veg Food Catering' },
        { value: ' Health Care Menu', label: 'Health Care Menu' },
        { value: ' No Requirement', label: 'No Requirement' },
      ]
    },
    {
      title: 'Venue ',
      options: [
        { value: 'Banquet Halls', label: 'Banquet Halls' },
        { value: 'Lawn', label: 'Lawn' },

      ]
    },
    {
      title: 'Entertainment',
      options: [
        { value: 'Comedian', label: 'Comedian' },
        { value: 'Motivational Speaker', label: 'Motivational Speaker' },
        { value: 'Photo Booth', label: 'Photo Booth' },

      ]
    },
    {
      title: 'Invitation',
      options: [
        { value: ' Invitations (cards)', label: ' Invitation (cards)' },

      ]
    },

    {
      title: 'Music',
      options: [
        { value: 'DJ', label: 'DJ' },
        { value: 'Entertainment', label: 'Entertainment' },
        { value: 'Singer', label: 'Singer' }
      ]
    },
    {
      title: 'Dress Up',
      options: [
        { value: 'Custumes', label: 'Custumes' },
        { value: 'Party hats', label: 'Party hats' },
        { value: 'Heaboopers', label: 'Heaboopers' },
        { value: 'Crowns', label: 'Crowns' },
        { value: 'Masks', label: 'Masks' },
      ]
    },
  ])


  return (
    <div className='container' >
      <div className='row mt-5 p-3' style={{ backgroundColor: '#C6E7FD', borderRadius: '10px' }}>
        <h2 className='text-center mt-3' style={{ fontFamily: 'Dancing Script', fontWeight: 'bold' }}>Fill for best quotation</h2>
        <h3 className='text-center' style={{ fontFamily: 'Dancing Script', fontWeight: 'bold' }}>Let's us organise best memorable birthday for you</h3>
        <HeadLine />
        {
          data.map((val) => {
            return (

              <BirthdayChecklist title={val.title} options={val.options} />
            )
          })
        }
        {
          input.map((cur) => {
            return (

              <Inputform title={cur.title} type={cur.type} placeholder={cur.placeholder} />
            )
          })
        }
        <center><input type='submit' value='SUBMIT' className='submit_btn' /></center>
      </div>


    </div>
  );
}
export default BirthdayForm