import React from 'react'
import Select from 'react-select';
import '../checkbox.css'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
const BirthdayChecklist = (props) => {
     console.log('child ',props.options)
  return (
       <div className='col-md-6 '>
       <div className='mb-5'>
       <lable className='title'>{props.title}
       </lable>
    <Select
    className='mt-1 shadow-sm  bg-body-tertiary rounded'
  closeMenuOnSelect={false}
  components={animatedComponents}
  isMulti
  options={props.options}
/>
        </div> 
        </div>
  )
}

export default BirthdayChecklist