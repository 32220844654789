import React from 'react'

const OutfitsIcon = () => {
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2062 0.700096C11.1312 0.737597 11.0437 0.837597 11.0125 0.931347C10.975 1.01885 10.9187 1.68135 10.875 2.40635C10.7937 3.9001 10.6875 4.79385 10.5062 5.5626C10.2937 6.44385 10.2187 7.34385 10.3 8.08135C10.3812 8.8501 10.5562 9.41885 11.0625 10.6251C12.05 12.9501 11.9875 13.7501 10.6687 16.1376C9.65625 17.9626 9.1875 19.2001 7.875 23.5688C7.4625 24.9313 6.8875 26.7813 6.59375 27.6751C6.3 28.5688 6.0625 29.3563 6.0625 29.4313C6.0625 29.7626 6.4375 29.9251 8.25 30.4001C13.1125 31.6751 18.525 31.7063 23.4062 30.4938C24.9 30.1188 25.65 29.8751 25.8 29.7126C25.975 29.5126 25.975 29.3751 25.775 28.8063C25.3812 27.6626 24.8687 26.0313 24.125 23.5688C22.7625 19.0563 22.3875 18.0626 21.325 16.1313C21.0375 15.6001 20.7437 15.0376 20.6812 14.8876C20.0937 13.5188 20.1187 12.6313 20.7937 11.0001C21.6625 8.8751 21.8312 8.1126 21.6937 6.79385C21.6562 6.4126 21.5687 5.85635 21.4937 5.5626C21.3125 4.8001 21.2062 3.9001 21.125 2.40635C21.0812 1.68135 21.025 1.01885 20.9875 0.931347C20.9125 0.718847 20.7 0.625097 20.3125 0.625097C19.775 0.625097 19.6187 0.762596 19.525 1.34385C19.2312 3.20635 18.1875 5.1876 16.8 6.5376C16.175 7.14385 16.0562 7.21885 15.8625 7.11885C15.6 6.98135 14.6812 6.0376 14.2812 5.5001C13.3562 4.2501 12.6937 2.7376 12.475 1.34385C12.4125 0.956347 12.3187 0.775097 12.15 0.693846C11.9562 0.606346 11.3937 0.612597 11.2062 0.700096Z" fill="#E01F62" />
            </svg>

        </>
    )
}

export default OutfitsIcon