import * as React from 'react';
import {
  Box, Button, List, ListItem, ListItemText, DialogTitle, DialogContent, DialogActions, Dialog, RadioGroup, Radio, FormControlLabel
} from '@mui/material/';
import { fontWeight } from '@mui/system';
import { BsCursor } from 'react-icons/bs';
const options = [
  'None',
  'Faizabad Road',
  'Gomti Nagar',
  'Vibhuti Khand',
  'Vishwas Khand',
  'BBD Road',
];
export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {

  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '60%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

const PreferLocation = () => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Select Location ');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 360, }}>
      <List component="div" role="group">
        <ListItem

          divider
          onClick={handleClickListItem}

          style={{ fontSize: '17px', fontWeight: '550', cursor: 'pointer', border: 'none' }}
        >
          <p> {`${value}`}</p>
        </ListItem>
        <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          f
        />
      </List>
    </Box>
  );
}
export default PreferLocation