import React, { useState, useEffect } from 'react'
import db from './components/firebase';
import { collection, getDocs } from "firebase/firestore";
import Slider from './components/HotelReview/Slider';
import HotelForm from './components/HotelReview/HotelForm';
import { IoIosShareAlt } from "react-icons/io";
import { FaPenNib } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { BiCalendarWeek } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import Rating from './components/rating/Rating';
const MehndiArtistPreview = ({ id }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const singleData = async () => {
            const mycollection = collection(db, "merchants");
            const data = await getDocs(mycollection);
            setData(
                data.docs
                    .filter((doc) => doc.id === id)
                    .map((doc) => ({ id: doc.id, ...doc.data() }))
            );
        };
        singleData();
    }, [id]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"

        });

    }, [])
    return (
        <>
            {
                data.map((data, i) => {
                    return (
                        <div className='container ' key={i}>
                            <div className='row d-flex '>
                                <div className='col-md-6'>
                                    <Slider

                                        img1={data?.PreviousWork[0]}
                                        img2={data?.PreviousWork[1]}
                                        img3={data?.PreviousWork[2]}
                                        img4={data?.PreviousWork[3]}
                                        key={i}
                                    />
                                    <div className='col-md-12'>
                                        <div className="btn-group form-control p-0" role="group">
                                            <button type="button" className="btn btn-outline-dark ">
                                                <FiHeart /> Save
                                            </button>
                                            <button type="button" className="btn btn-outline-dark">
                                                <FaPenNib /> Write a review
                                            </button>
                                            <button type="button" className="btn btn-outline-dark">
                                                <IoIosShareAlt /> Share
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>

                                    <div className='head_section'>
                                        <div>
                                            <h4 className='heading'>{data.business_name}</h4>
                                        </div>
                                        <div>
                                            <button className='cln_btn'>Check Availability &nbsp;
                                                <BiCalendarWeek className='cln_icon text-white' />
                                            </button>
                                        </div>
                                    </div>
                                    <div> <FaMapMarkerAlt className='cln_icon' />
                                        {data.contactInfo.businessAddress} <span><a href={`tel:${data.contactInfo.mobno}`} className="btn" style={{ backgroundColor: '#40B0FA', color: '#fff', fontWeight: 'bold' }}> Phone Number </a></span></div>

                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className='star_rate' >
                                                <div className="star">
                                                    <BsStarFill className='small' />
                                                    <BsStarFill className='small' />
                                                    <BsStarFill className='small' />
                                                    <BsStarFill className='small' />
                                                </div>
                                            </div>
                                            <div>
                                                {`${data.avg_rating || 0} (${data.review || 0} Reviews)`}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{data.basicDetails.business}</p>
                                    <button className='btn' style={{ backgroundColor: '#40B0FA', color: '#fff', fontWeight: 'bold' }}>Book Now</button>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center ' >
                                <div className='col-md-6'>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data.describeBusiness }}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <HotelForm />
                                </div>
                            </div>
                            <Rating />
                        </div>
                    )
                })
            }
        </>
    )
}

export default MehndiArtistPreview