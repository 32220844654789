import React from 'react'
import './Venue.css';
import { Link, useLocation } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";
import Nonveg from './../../nonveg.png'
import Veg from './../../veg.png';
import People from './../../people.png';
import Location from './../../location.png';

const VenueBox = ({ vegPrice, nonVegPrice, main_image, small_1, small_2, small_3, title, avg_rating, review, location, price, id, fixedCapacity, floatingCapacity }) => {
  const url = useLocation();
  const path = url.pathname;
  //console.log(path)
  return (

    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
      <Link to={`${path}/v1/${id}`} style={{ textDecoration: 'none', color: 'black' }}>
        <div className="card card_container">
          <div className="card-header p-0">
            <img src={main_image} alt="Event Planet" class="img-style" style={{ height: '220px' }} />
          </div>
          <div className="three_section">
            <img src={small_1} class="three-style" alt="Event Planet" />
            <img src={small_2} class="three-style" alt="Event Planet" />
            <img src={small_3} class="three-style" alt="Event Planet" />
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h5 class="title-1"> {`${title?.length > 15 ? title?.slice(0, 15) : title}...`}</h5>
              </div>
              <div className="rating">
                <BsFillStarFill className="text-warning" />
                <span>{avg_rating}</span>
                ({review} reviews)
              </div>
            </div>

            <div class="d-flex  align-items-center">
              <div>
                <img src={Location} style={{ objectFit: 'contain' }} alt="Event Planet" />
              </div>
              <div className="text-color-1">
                {`${location?.length > 30 ? location.slice(0, 30) : location}...`}
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-around my-2'>
              <div className='food_tag d-flex'>
                <img src={Veg} alt="Event Planet" />
                &#x20B9; {vegPrice}
              </div>
              <div className='food_tag d-flex'>
                <img src={Nonveg} alt="Event Planet" />
                &#x20B9; {nonVegPrice}
              </div>

            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="price-tag">
                <img src={People} alt="Event Planet" /> {fixedCapacity} to {floatingCapacity}
              </div>
              <div> <BsHeart className="heart-color" /></div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default VenueBox