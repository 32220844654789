import React from "react";
import ProgressBar from "./ProgressBar";

const RatingDistribution = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="ratingdistribution">
            <h4>Rating Distribution</h4>
            <ProgressBar rating="2.3" review="5" />
            <ProgressBar rating="1.8" review="11" />
            <ProgressBar rating="4.2" review="12" />
            <ProgressBar rating="3.2" review="10" />
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingDistribution;
