
import React from 'react';
import { useState, useEffect } from 'react';

import WeddingChecklist from './WeddingChecklist';
import Inputform from '../Inputform';
import HeadLine from '../HeadLine';
import { fontWeight } from '@mui/system';


function WeddingForm() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"

    });

  }, [])
  const [input, setInput] = useState([
    {
      title: ' Enter Name',
      type: 'text',
      placeholder: 'Enter your name'
    },
    {
      title: 'Enter Email',
      type: 'email',
      placeholder: 'Enter your email'
    },
    {
      title: 'Enter Mobile Number',
      type: 'tel',
      placeholder: 'Enter your Mobile Number',
      pattern: '[0-9]{10}'
    },
    {
      title: 'Enter Address',
      type: 'address',
      placeholder: 'Enter your Address'
    }

  ])
  const [data, setData] = useState([
    {
      title: 'Photographer',
      options: [
        { value: 'Tradistiona Photo & Video', label: 'Tradistiona Photo & Video' },
        { value: 'Cinematic Video', label: 'Cinematic Video' },
        { value: 'Candid Shoot', label: 'Candid Shoot' },
        { value: 'Pre Wedding Shooting', label: 'Pre Wedding Shooting' },
        { value: 'No Requirement', label: 'No Requirement' },
      ]
    },
    {
      title: 'Green Wear',
      options: [
        { value: 'Sherwani', label: 'Sherwani' },
        { value: 'Wedding Shoot', label: 'Wedding Shoot' },
        { value: 'Sherwani on Rent', label: 'Sherwani on Rent' },
        { value: 'Nehru Jackets', label: 'Nehru Jackets' },
        { value: 'Pagri', label: 'Pagri' },
      ]
    },
    {
      title: 'Food ',
      options: [
        { value: 'Full Catering Services', label: 'Full Catering Services' },
        { value: 'Small Function Catering', label: 'Small Function Catering' },
        { value: 'Desert', label: 'Vanilla' }
      ]
    },
    {
      title: 'Makeup ',
      options: [
        { value: 'Bridal Makeup', label: 'Bridal Makeup' },
        { value: 'Family Makeup', label: 'Family Makeup' },

      ]
    },
    {
      title: 'Mehndi',
      options: [
        { value: 'Mehndi Artist', label: 'Mehndi Artist' },

      ]
    },
    {
      title: 'Invitation',
      options: [
        { value: 'Wedding Invitation (cards)', label: 'Wedding Invitation (cards)' },

      ]
    },
    {
      title: 'Bridal Wear',
      options: [
        { value: 'Bridal Lehengas', label: 'Bridal Lehengas' },
        { value: 'Bridal Lehengas on Rent', label: 'Bridal Lehengas on Rent' },
        { value: 'Trousseau Sarees', label: 'Trousseau Sarees' },
        { value: 'Anarkalis', label: 'Anarkalis' },
        { value: 'Light Lehengas', label: 'Light Lehengas' }
      ]
    },
    {
      title: 'Photographer',
      options: [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    },
    {
      title: 'Photographer',
      options: [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    },
  ])


  return (
    <div className='container' >

      <div className='row mt-5 p-3' style={{ backgroundColor: '#C6E7FD', borderRadius: '10px' }}>
        <h2 className='text-center mt-3' style={{ fontFamily: 'Dancing Script', fontWeight: 'bold' }}>Fill for best quotation</h2>
        <h3 className='text-center' style={{ fontFamily: 'Dancing Script', fontWeight: 'bold' }}>Let's start new journey at best price</h3>
        <HeadLine />
        {
          data.map((val) => {
            return (
              <WeddingChecklist title={val.title} options={val.options} />
            )
          })
        }
        {
          input.map((cur) => {
            return (

              <Inputform title={cur.title} type={cur.type} placeholder={cur.placeholder} pattern={cur.pattern} />
            )
          })
        }
        <center><input type='submit' value='SUBMIT' className='submit_btn' /></center>
      </div>


    </div>
  );
}
export default WeddingForm