import React,{useState} from 'react'

const WriteRating = () => {
  const [data, setData]=useState(); 
  const btnHandler = () =>{
    alert(`Your Rating is ${data}`)
  }
  return (
    <>
    <div className='container'>
       <div className='row'>
         <div className='writerating'>
            <div>
               <textarea placeholder="Write your experience in new words*" value={data} onChange={(e)=>setData(e.target.value)}></textarea>
            </div>
            <div>
            <button onClick={btnHandler}>Submit</button>
            </div>
         </div>
       </div>
    </div>
    </>
  )
}

export default WriteRating