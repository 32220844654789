import React from 'react'

const WeddingIncurance = () => {
    return (
        <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4209 0.53125C12.085 1.5 7.95215 2.42188 2.55371 3.17969C0.545898 3.46094 0.428711 3.5 0.350586 3.95312C0.327148 4.08594 0.319336 6.65625 0.327148 9.66406L0.350586 15.1328L0.624023 14.7031C0.772461 14.4688 1.06152 14.0547 1.27246 13.7891L1.63965 13.3047V9.49219C1.64746 5.48438 1.66309 5.30469 2.00684 4.92188C2.26465 4.64062 2.55371 4.54688 3.88183 4.35156C8.00683 3.72656 10.9287 3.09375 13.6162 2.22656L14.999 1.78125L16.3896 2.22656C19.1006 3.09375 21.5146 3.625 25.6865 4.27344C27.4365 4.54688 27.7256 4.63281 27.9912 4.92188C28.335 5.30469 28.3506 5.48438 28.3584 9.49219V13.2969L28.7412 13.7891C28.9521 14.0547 29.2412 14.4688 29.3818 14.7031L29.6475 15.1328L29.6709 9.66406C29.6787 6.65625 29.6709 4.08594 29.6475 3.95312C29.5693 3.5 29.4521 3.46094 27.4443 3.17969C22.1865 2.4375 18.3037 1.57812 15.7646 0.59375C15.3896 0.445312 15.0381 0.328125 14.9834 0.328125C14.9287 0.335938 14.6709 0.421875 14.4209 0.53125Z" fill="#E01F62" />
            <path d="M13.9739 3.50781C11.5911 4.3125 8.59107 4.96094 3.63013 5.73438L3.00513 5.83594L2.98169 8.89844C2.97388 10.5859 2.97388 11.9688 2.98951 11.9688C3.00513 11.9688 3.30982 11.7734 3.66919 11.5312C4.99732 10.6484 6.45045 10.0625 7.95045 9.8125C9.16138 9.61719 11.0754 9.6875 12.1692 9.97656C13.0442 10.2031 14.1458 10.6172 14.6536 10.9141L14.9739 11.1016L15.6692 10.7578C16.052 10.5625 16.4114 10.4062 16.4739 10.4062C16.5442 10.4062 16.5598 10.3594 16.5208 10.2656C16.2004 9.53125 16.2786 9.22656 17.0442 8.15625C17.9036 6.96875 17.7786 7.00781 20.3489 7.00781C22.9895 7.00781 22.7864 6.92969 23.7864 8.40625C24.302 9.17188 24.3098 9.1875 24.2708 9.61719C24.2551 9.85938 24.2161 10.125 24.1848 10.2109C24.1458 10.3438 24.2551 10.4219 25.0598 10.8047C25.5598 11.0469 26.1926 11.4141 26.4661 11.6094C26.7317 11.8047 26.9661 11.9688 26.9895 11.9688C27.013 11.9688 27.0208 10.5859 27.013 8.89844L26.9895 5.83594L26.3645 5.73438C21.5442 4.98438 18.6379 4.35938 16.1145 3.54688L14.9583 3.17969L13.9739 3.50781Z" fill="#FFC338" />
            <path d="M18.1406 8.92969L17.6562 9.58594L18.0781 9.97656C18.3047 10.1875 18.9063 10.75 19.4063 11.2188L20.3125 12.0625L20.6406 11.7812C20.8281 11.6328 21.4297 11.0703 21.9922 10.5391L23 9.57031L22.5781 8.97656L22.1484 8.375H20.8516C20.1406 8.375 19.3438 8.35156 19.0938 8.32812L18.625 8.28125L18.1406 8.92969Z" fill="#E01F62" />
            <path d="M7.99219 11.1484C4.66407 11.75 1.82031 14.2266 0.789063 17.4219C0.4375 18.5 0.351562 19.0938 0.351562 20.3672C0.359375 21.2031 0.398438 21.7344 0.5 22.2031C1.4375 26.5703 5.1875 29.6328 9.60938 29.6406C10.1484 29.6406 10.8203 29.6016 11.1016 29.5547C14.5625 28.9922 17.4609 26.5234 18.5156 23.2422C19.2813 20.8516 19.1016 18.3594 18 16.1719C17.6563 15.4844 17.1406 14.7109 16.9844 14.6484C16.8828 14.6094 16.2188 15.0469 16.0313 15.2734C15.9609 15.3516 16.0078 15.4688 16.2188 15.7656C17.2109 17.1406 17.7266 18.9688 17.6328 20.75C17.5313 22.6641 16.7969 24.3828 15.4922 25.8047C13.8672 27.5625 11.2188 28.5625 8.88282 28.3047C5.27344 27.8984 2.51563 25.4062 1.79688 21.8984C1.07031 18.3203 2.875 14.7266 6.21094 13.1328C7.28125 12.6172 8.03126 12.4375 9.23438 12.3828C10.3516 12.3281 11.1016 12.4219 12.0547 12.7266L12.6563 12.9141L13.1641 12.4609C13.4453 12.2109 13.6719 11.9844 13.6719 11.9609C13.6719 11.8125 12.1484 11.3047 11.2578 11.1484C10.4609 11.0078 8.76563 11.0078 7.99219 11.1484Z" fill="#E01F62" />
            <path d="M16.9825 11.6484C12.3497 13.4766 9.96684 18.4297 11.4512 23.1562C11.6856 23.8984 12.2793 25.0937 12.7168 25.6953L13.0137 26.1016L13.3262 25.8906C13.4981 25.7734 13.7403 25.6016 13.8575 25.5078L14.0684 25.3359L13.7637 24.8828C11.5684 21.6406 11.9512 17.4062 14.6934 14.6797C15.7012 13.6797 17.0684 12.9062 18.3418 12.5937C18.5684 12.5391 18.7481 12.4766 18.7325 12.4453C18.6934 12.3359 17.6778 11.4219 17.6075 11.4219C17.5684 11.4297 17.2872 11.5234 16.9825 11.6484Z" fill="#E01F62" />
            <path d="M22.4227 11.9453L21.8681 12.4609L22.5165 12.6484C25.2509 13.414 27.3602 15.5781 28.1024 18.3672C28.2821 19.0156 28.3056 19.2812 28.3056 20.3281C28.3056 21.3906 28.2821 21.6328 28.1024 22.2968C27.2977 25.3047 25.0399 27.4844 22.0477 28.1406C20.7821 28.4219 18.8837 28.3125 17.7587 27.8906L17.3368 27.7344L16.8915 28.1484C16.6493 28.375 16.3993 28.6015 16.3446 28.6484C16.2665 28.7187 16.3993 28.7969 16.9774 29.0234C18.9384 29.7734 21.2977 29.8359 23.2431 29.1875C26.5165 28.1015 28.9306 25.2969 29.5399 21.8906C29.6884 21.0859 29.6649 19.3515 29.4931 18.5703C28.8915 15.7343 27.1571 13.3906 24.7118 12.1015C24.1806 11.8281 23.204 11.4218 23.0556 11.4218C23.0165 11.4218 22.7352 11.6562 22.4227 11.9453Z" fill="#E01F62" />
            <path d="M8.44617 13.7735C5.03991 14.4375 2.61804 17.7109 3.03991 21.0469C3.48523 24.5 6.22742 26.9688 9.62585 26.9688C10.3915 26.9688 11.5243 26.8047 11.6571 26.6719C11.6884 26.6406 11.5555 26.3672 11.3602 26.0703C10.6805 25.0156 10.1571 23.75 9.86804 22.4219C9.67273 21.5078 9.67273 19.1484 9.86804 18.2344C10.1571 16.9063 10.6805 15.6406 11.3602 14.5859C11.5555 14.2813 11.6884 14.0156 11.6571 13.9844C11.4149 13.75 9.27429 13.6094 8.44617 13.7735Z" fill="#FFC338" />
            <path d="M18.9435 13.8203C18.6466 13.8828 18.3732 13.961 18.3419 13.9922C18.3107 14.0156 18.4044 14.2266 18.5607 14.4531C19.2326 15.4531 19.8419 16.9219 20.131 18.2344C20.3263 19.1484 20.3263 21.5078 20.131 22.4219C19.8419 23.75 19.3185 25.0156 18.6388 26.0703C18.4435 26.3672 18.3107 26.6406 18.3419 26.6719C18.4747 26.8047 19.6076 26.9688 20.3732 26.9688C23.2716 26.9688 25.7872 25.1172 26.6779 22.3438C27.2404 20.5938 27.0763 18.8906 26.1857 17.1641C25.8888 16.5859 25.6779 16.3203 25.0372 15.6797C23.9747 14.6172 23.0138 14.086 21.6779 13.8047C20.9591 13.6563 19.6701 13.6641 18.9435 13.8203Z" fill="#FFC338" />
            <path d="M14.7498 16.7188C13.3982 18.75 13.3201 21.4453 14.5545 23.6094C14.7342 23.9297 14.9373 24.1953 14.9998 24.1953C15.1638 24.1953 15.742 23.1563 15.9763 22.4375C16.5857 20.5938 16.3748 18.5703 15.3982 16.9688C15.2342 16.6953 15.0623 16.4531 15.0232 16.4453C14.9842 16.4297 14.8592 16.5547 14.7498 16.7188Z" fill="#FFC338" />
            <path d="M0.78125 26.3203C0.78125 26.5234 1.36719 28.0703 1.69531 28.7187C3.07812 31.4765 5.21875 33.4531 8.28125 34.8047C13.1797 36.9609 14.8984 37.6797 15.0859 37.6406C15.4531 37.5703 22.5234 34.4609 23.2266 34.0625C25.4219 32.8125 27.1797 30.9609 28.3047 28.7187C28.6719 27.9922 29.2422 26.4531 29.1953 26.3203C29.1875 26.2812 29.0859 26.3828 28.9688 26.5547C28.625 27.0781 27.8438 27.9297 27.3047 28.375C26.9766 28.6406 26.6016 29.0625 26.3203 29.4765C25.4062 30.8047 24.125 31.9922 22.7266 32.8203C22.3672 33.0312 20.5625 33.8593 18.7266 34.6562C15.8906 35.8906 15.3281 36.1093 15 36.1093C14.6797 36.1093 14.1406 35.8984 11.5078 34.75C9.79688 34.0078 8.14062 33.2656 7.82812 33.1093C6.17969 32.2812 4.74219 31.0156 3.67188 29.4687C3.39844 29.0625 3 28.625 2.66406 28.3359C2.10156 27.8672 1.59375 27.3203 1.07812 26.625C0.914062 26.414 0.78125 26.2734 0.78125 26.3203Z" fill="#E01F62" />
            <path d="M14.2959 29.9141C12.6943 30.7266 10.7255 31.1016 8.81148 30.9453C8.03805 30.8828 6.9443 30.6797 6.53023 30.5156C5.96773 30.2969 7.45211 31.4141 8.3193 31.8672C9.06148 32.25 14.8662 34.7812 15.0068 34.7812C15.124 34.7812 20.6787 32.3672 21.4443 31.9844C22.1474 31.6406 22.7412 31.2578 23.2802 30.8125C23.5927 30.5547 23.6552 30.4766 23.5146 30.5156C20.7021 31.2891 18.1318 31.1016 15.7802 29.9531C15.374 29.75 15.0224 29.5859 14.999 29.5781C14.9755 29.5781 14.663 29.7266 14.2959 29.9141Z" fill="#FFC338" />
        </svg>

    )
}

export default WeddingIncurance