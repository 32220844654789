import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import VenueBox from '../venue/VenueBox'
// import SliderContainer from '../SliderContainer'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import db from '../firebase';
import { query, where, collection, getDocs } from 'firebase/firestore';
import ServiceSubCategory from '../service/ServiceSubCategory';
import FilterContainer from '../filter/FilterContainer';
const VendorContainer = ({ cat_id, id }) => {
  const [data, setData] = useState([]);
  const [filteredSet, setFilteredSet] = useState([]);
  console.log(filteredSet)
  const [loading, setLoading] = useState(true)
  const [subCatId, setSubCatId] = useState(null);

  const getCategories = async (catID = cat_id) => {
    console.log(cat_id, "MATCHED")
    const mycollection = collection(db, 'merchants');
    let q = query(mycollection, where('serviceId', '==', catID));
    const data = await getDocs(q);
    setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    setLoading(false);
  }
  useEffect(() => {
    console.log("INITIAL PROCESS")
    getCategories(cat_id)
  }, [cat_id])
  useEffect(() => setFilteredSet(data), [data])

  useEffect(() => {
    if (!subCatId) return
    const applyFilter = data.filter((a) => {
      return a.sub_cat_id === subCatId;
    })
    setFilteredSet(applyFilter);
  }, [subCatId])
  const handleClick = (event, sub_cat_id) => {
    setSubCatId(sub_cat_id);
  };

  return (

    <>
      {/* <ServiceSubCategory cat_id={cat_id} handleClick={handleClick} /> */}
      <div class="container">
        <div className="row">

          {
            loading && (
              <>
                <div className='col-md-4'>
                  <div className='m-2'>
                    <Skeleton height={220} />
                    <div className='d-flex justify-content-between'>
                      <Skeleton height={100} width={110} />
                      <Skeleton height={100} width={110} />
                      <Skeleton height={100} width={110} />
                    </div>
                    <Skeleton count={4} height={32} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='m-2'>
                    <Skeleton height={220} />
                    <div className='d-flex justify-content-between'>
                      <Skeleton height={100} width={110} />
                      <Skeleton height={100} width={110} />
                      <Skeleton height={100} width={110} />
                    </div>
                    <Skeleton count={4} height={32} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='m-2'>
                    <Skeleton height={220} />
                    <div className='d-flex justify-content-between'>
                      <Skeleton height={100} width={110} />
                      <Skeleton height={100} width={110} />
                      <Skeleton height={100} width={110} />
                    </div>
                    <Skeleton count={4} height={32} />
                  </div>
                </div>
              </>
            )
          }
          {
            filteredSet?.map((item, i) => {
              const { id, cat_id, main_image, small_1, small_2, small_3, title, avg_rating, review, location, price } = data;
              return (
                <VenueBox
                  key={i}
                  main_image={item.profilePicture.image}
                  small_1={item.PreviousWork[0]}
                  small_2={item.PreviousWork[1]}
                  small_3={item.PreviousWork[2]}
                  title={item?.business_name}
                  // fixedCapacity={item.serviceAndPricing.fixedCapacity}
                  // floatingCapacity={item.serviceAndPricing.floatingCapacity}
                  avg_rating="4.3"
                  review="15"
                  location={item.vendorProfile?.street}
                  vegPrice={item.thingsToKnow?.vegPrice}
                  nonVegPrice={item.thingsToKnow?.nonVegPrice}
                  id={item.id}
                />
              )
            })
          }
        </div>
      </div>

    </>
  )
}

export default VendorContainer