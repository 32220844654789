import React, { useState, useEffect } from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import db from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import VenueIcon from "../icons/VenueIcon";
import VendorIcon from "../icons/VendorIcon";
import DestinationWeddingIcon from "../icons/DestinationWeddingIcon";
import BirthdayIcon from "../icons/BirthdayIcon";
import CorporateIcon from "../icons/CorporateIcon";
import OutfitsIcon from "../icons/OutfitsIcon";
import GovernmentIcon from "../icons/GovernmentIcon";
import TransportationIcon from "../icons/TransportationIcon";
import WeddingIncurance from "../icons/WeddingIncurance";
import Birthday from './../cake.svg';
import { StepButton } from "@mui/material";
import ServiceSubCategory from "./service/ServiceSubCategory";
const Header = () => {
  const [subCatId, setSubCatId] = useState(null);
  const paramas = useParams();
  console.log('Parameter', paramas);
  const location = useLocation();
  console.log("Current Location ", location.pathname);
  const lastPart = location.pathname.split("/").pop();
  console.log('Last Part', lastPart)
  const [CurCatId, setCurCatId] = useState(null);
  console.log('Current Cat id', CurCatId);
  const [data, setData] = useState([
    {
      cat_name: "Venue",
      href: "venue",
      icon: <VenueIcon />,
      id: "trvwyuvRqNTuZQh8KNbi"
    },
    {
      cat_name: "Vendors",
      href: "vendors",
      icon: <VendorIcon />,
      id: "lIctLmJMzKGyp9taXkFM"
    },
    {
      cat_name: "Beauty & Outfits",
      href: "outfits",
      icon: <OutfitsIcon />,
      id: "cpz6DDRUONI2VKYm89Hp"
    },
    {
      cat_name: "Destination Wedding",
      href: "destination-wedding",
      icon: <DestinationWeddingIcon />,
      id: "TwtVyXeU6ZlwN9HWtSdj"
    },
    {
      cat_name: "Travel & Transportation",
      href: "transportation",
      icon: <TransportationIcon />,
      id: "TJ49nhNUSBwzUowlRPmA"
    },
    {
      cat_name: "Birthday",
      href: "birthday",
      icon: <BirthdayIcon />,
      id: "KqnhUfe1vA1ZVOXHzPOO"
    },
    {
      cat_name: "Government & Corporate Events",
      href: "government-events",
      icon: <GovernmentIcon />,
      id: "eQzhKP8UvvGBgfrDRFr4"
    },
    {
      cat_name: "Wedding Insurance",
      href: "wedding-insurance",
      icon: <WeddingIncurance />,
      id: "1VzpExxYvO14f6epMMbB"
    },
  ]);

  // console.log('NAVBAR ', data);
  useEffect(() => {
    setCurCatId(lastPart)
  }, [lastPart]);

  const handleClick = (event, sub_cat_id) => {
    setSubCatId(sub_cat_id);
  };
  return (
    <>
      <div className="container">
        <div className="row my-2">
          <div className="col-lg-12">
            <div className="service_container">
              {data.map((nav) => {
                return (
                  <NavLink to={`${nav.href}/${nav.id}`} key={nav.id} onClick={() => setCurCatId(nav.id)}>
                    <div className="service">
                      <div className="service_icon">
                        {nav.icon}
                      </div>
                      <div className="service_title">{nav.cat_name}</div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ServiceSubCategory cat_id={lastPart} handleClick={handleClick} />
    </>
  );
};

export default Header;
